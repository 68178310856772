// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-demox-js": () => import("./../../../src/pages/demox.js" /* webpackChunkName: "component---src-pages-demox-js" */),
  "component---src-pages-ecomm-360-fraud-cyber-defence-for-shopify-js": () => import("./../../../src/pages/ecomm360-fraud-cyber-defence-for-shopify.js" /* webpackChunkName: "component---src-pages-ecomm-360-fraud-cyber-defence-for-shopify-js" */),
  "component---src-pages-ecomm-360-fraud-cyber-defence-js": () => import("./../../../src/pages/ecomm360-fraud-cyber-defence.js" /* webpackChunkName: "component---src-pages-ecomm-360-fraud-cyber-defence-js" */),
  "component---src-pages-ecomm-360-fraud-prevention-for-shopify-js": () => import("./../../../src/pages/ecomm360-fraud-prevention-for-shopify.js" /* webpackChunkName: "component---src-pages-ecomm-360-fraud-prevention-for-shopify-js" */),
  "component---src-pages-ecomm-360-fraud-prevention-js": () => import("./../../../src/pages/ecomm360-fraud-prevention.js" /* webpackChunkName: "component---src-pages-ecomm-360-fraud-prevention-js" */),
  "component---src-pages-fraud-cyber-defence-ecomm-360-for-shopify-js": () => import("./../../../src/pages/fraud-cyber-defence-ecomm360-for-shopify.js" /* webpackChunkName: "component---src-pages-fraud-cyber-defence-ecomm-360-for-shopify-js" */),
  "component---src-pages-fraud-cyber-defence-ecomm-360-js": () => import("./../../../src/pages/fraud-cyber-defence-ecomm360.js" /* webpackChunkName: "component---src-pages-fraud-cyber-defence-ecomm-360-js" */),
  "component---src-pages-fraud-prevention-ecomm-360-for-shopify-js": () => import("./../../../src/pages/fraud-prevention-ecomm360-for-shopify.js" /* webpackChunkName: "component---src-pages-fraud-prevention-ecomm-360-for-shopify-js" */),
  "component---src-pages-fraud-prevention-ecomm-360-js": () => import("./../../../src/pages/fraud-prevention-ecomm360.js" /* webpackChunkName: "component---src-pages-fraud-prevention-ecomm-360-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */)
}

